import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="269081280336-bk8nj281pg63b8hsm9rqjqpd9inp2i58.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
