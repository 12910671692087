import React, { useEffect, useState } from "react";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import { GoChevronRight } from "react-icons/go";
import { FaDownload } from "react-icons/fa6";
import mike from "../assets/svg/projectsiconmike.svg";
import plus from "../assets/svg/projectsplusicon.svg";
import bgimg from "../assets/images/Frame.jpg";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../config.js";
import axios from "axios";

// Reusable ProjectCard component
const ProjectCard = ({
  img,
  title,
  description,
  bgColor,
  textColor,
  link,
  id,
}) => {
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Function to limit the description to 10 words
  const getShortDescription = () => {
    const words = description.split(" ");
    return words.length > 10
      ? words.slice(0, 10).join(" ") + "..."
      : description;
  };

  const handleapi = () => {
    navigate(link);
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getPdfDetails/${id}`, {
        headers: {
          // Token: `${authToken}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Project_${id}.pdf`); // Set filename dynamically based on project id
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  return (
    // <Link to={link} className="sm:w-[80%] md:w-[48%] xs:w-full min-w-[48%]">
    <div
      onClick={handleapi}
      className={`flex gap-4 sm:gap-6 ${bgColor} ${textColor} items-center py-3 w-full ps-6 pr-8 rounded-xl  lg:w-[48%] xl:w-[49%]  xs:w-full  justify-between`}
    >
      <p>
        <img src={img} alt="" className="w-8 h-8 sm:w-10 sm:h-10" />
      </p>
      <div className="w-full">
        <p className="text-[18px] sm:text-[21px] inter_ff mb-0 flex justify-between items-center font-bold">
          {title}

          {title == "Start New Project" ? null : (
            <p
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(id);
              }}
              className="mt-2 cursor-pointer mb-0"
            >
              <FaDownload />
            </p>
          )}
        </p>
        <p className="text-[15px]  sm:text-[17px] inter_ff font-medium ">
          {showFullDescription ? description : getShortDescription()}
          {description.split(" ").length > 10 && (
            <span
              onClick={toggleDescription}
              className="text-blue-500 cursor-pointer"
            >
              {showFullDescription ? " Show Less" : " Read More"}
            </span>
          )}
        </p>
      </div>
    </div>
    // </Link>
  );
};

const Templates = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        console.log(authToken);

        const response = await axios.get(`${BASE_URL}/api/getProject`, {
          headers: {
            Token: `${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data && response.data.status === "success") {
          const projectData = response.data.data; // Assuming data contains the array of projects

          const updatedProjects = projectData.map((project, index) => ({
            id: index + 1,
            img: mike, // Assuming you want to use the same image for all projects
            title: project.projectName, // Assign the project name from API response
            description: project.description || "Default description", // You can replace this with actual descriptions if available
            bgColor: "bg-[#F5FBFF]",
            textColor: "text-black",
            link: `/dashboard2/${project._id}`,
            _id: project?._id, // You can modify this link based on your requirements
          }));

          // Add the "Start New Project" card manually
          //   updatedProjects.unshift({
          //     id: 0,
          //     img: plus,
          //     title: "Start New Project",
          //     description: "Create Text with Voice Speech with inspire",
          //     bgColor: "bg-[#498BF7]",
          //     textColor: "text-white",
          //     link: `/qrgenerate`,
          //   });

          setProjects(updatedProjects);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
    window.onpopstate = () => {
      navigate("/dashboard3");
    };
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
      }}
    >
      <Navbar />
      <div className="w-full flex-grow">
        <div className="mx-4 sm:mx-8 shadow-md lg:mx-12 p-4 sm:p-8 lg:p-10 pb-12 pt-5 rounded-xl mt-5 bg-white">
          <div className="flex items-center justify-between">
            <h1 className="text-[22px] sm:text-[26px] text-[#161C2D] inter_ff font-bold">
              Template List
            </h1>
            <Link to="/qrgenerate">
              <button className="bg-[#008CD2] xs:hidden block text-white sm:px-4 sm:text-[12px] font-semibold text-[15px] inter_ff py-2 px-8 rounded-full">
                Start New Project
              </button>
            </Link>
          </div>
          {/* <p className="text-[12px] sm:text-[13px] font-normal inter_ff">
            Create or edit projects
          </p> */}
          <div className="pt-6 sm:pt-8 md:pt-8 lg:pt-8 xl:pt-8 w-full sm:justify-center flex flex-wrap gap-6 sm:gap-8">
            {projects.map((project) => {
              console.log(project);
              return (
                <ProjectCard
                  key={project.id}
                  img={project.img}
                  title={project.title}
                  description={project.description}
                  bgColor={project.bgColor}
                  textColor={project.textColor}
                  link={project.link}
                  id={project._id}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Templates;

// // import React, { useEffect, useState } from 'react';
// // import Navbar from './Layout/Navbar';
// // import Footer from './Layout/Footer';
// // import { GoChevronRight } from "react-icons/go";
// // import mike from "../assets/svg/projectsiconmike.svg";
// // import plus from "../assets/svg/projectsplusicon.svg";
// // import bgimg from "../assets/images/Frame.jpg";
// // import { Link } from 'react-router-dom';
// // import { BASE_URL } from "../config.js";
// // import axios from 'axios';
// // // Reusable ProjectCard component
// // const ProjectCard = ({ img, title, description, bgColor, textColor, link }) => {

// //   return (
// //     <>

// //       <Link to={link} className='sm:w-[80%]  md:w-[48%] xs:w-full min-w-[48%]'>
// //         <div className={`flex gap-4 sm:gap-6 ${bgColor} ${textColor} items-center py-3 w-full ps-6 pr-8 rounded-xl`}>
// //           <p><img src={img} alt="" className="w-8 h-8 sm:w-10 sm:h-10" /></p>
// //           <div className='w-full'>
// //             <p className='text-[18px] sm:text-[21px] inter_ff flex justify-between items-center font-bold'>
// //               {title} <GoChevronRight />
// //             </p>
// //             <p className='text-[15px] sm:text-[17px] inter_ff font-medium pt-2 sm:pt-3'>{description}</p>
// //           </div>
// //         </div>
// //       </Link>
// //     </>
// //   );
// // };

// // const Projects = () => {
// //   const [projects, setProjects] = useState([]);
// //   useEffect(() => {
// //     const fetchData = async () => {
// //       const data = [
// //         { id: 1, img: plus, title: "Start New Project", description: "Create Text with Voice Speech with inspire", bgColor: "bg-[#498BF7]", textColor: "text-white", link: "/dashboard2" },
// //         { id: 2, img: mike, title: " Project Name", description: "The generated Lorem Ipsum is therefore always free c...", bgColor: "bg-[#F5FBFF]", textColor: "text-black" },
// //         { id: 2, img: mike, title: " Project Name", description: "The generated Lorem Ipsum is therefore always free c...", bgColor: "bg-[#F5FBFF]", textColor: "text-black" },
// //         { id: 2, img: mike, title: "Project", description: "The generated Lorem Ipsum is therefore always free c...", bgColor: "bg-[#F5FBFF]", textColor: "text-black" },
// //         { id: 2, img: mike, title: " Project Name", description: "The generated Lorem Ipsum is therefore always free c...", bgColor: "bg-[#F5FBFF]", textColor: "text-black" },
// //         { id: 2, img: mike, title: " Project Name", description: "The generated Lorem Ipsum is therefore always free c...", bgColor: "bg-[#F5FBFF]", textColor: "text-black" },
// //         // Add more project data as needed
// //       ];
// //       setProjects(data);
// //     };

// //     fetchData();
// //   }, []);
// //   const [projectsName, setProjectName] = useState([]);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const authToken = localStorage.getItem('authToken');
// //         console.log(authToken)
// //         const response = await axios.get(`${BASE_URL}/api/getProject`, {
// //           headers: {
// //             'Token': `${authToken}`,
// //             'Content-Type': 'application/json',
// //         },
// //         });
// //         if (authToken) {
// //           localStorage.setItem('authToken', authToken);
// //           localStorage.setItem('projectName', response.data);

// //         } else {
// //           console.error('Auth token not found in response');
// //           return;
// //         }
// //         console.log(response.data);
// //         if (!response.ok) {
// //           throw new Error('Error fetching projects');
// //         }

// //         const data = await response.json();
// //         setProjects(data.data); // Assuming the API response structure
// //       } catch (error) {
// //         console.error('Error fetching projects:', error);
// //       }
// //     };

// //     fetchData();
// //   }, []);
// //   useEffect(() => {

// //     const projectName = localStorage.getItem('projectName');
// //     if (projectsName) {
// //       setProjectName(projectsName);
// //     }

// //   }, []);

// //   return (
// //     <div className='min-h-screen flex flex-col' style={{
// //       backgroundImage: `url(${bgimg})`,
// //       backgroundSize: 'cover',
// //     }}>

// //       <Navbar />

// //       <div className='w-full  lg:mt-28 xl:mt-32  mt-28 flex-grow'>
// //         <div className='mx-4 sm:mx-8 shadow-md lg:mx-12 p-4 sm:p-8 lg:p-10 pb-12 py-4 rounded-xl pt-12 bg-white'>
// //           <h1 className='text-[22px] sm:text-[26px] text-[#161C2D] inter_ff font-bold'>Speech to Text</h1>
// //           <p className='text-[12px] sm:text-[13px] font-normal inter_ff'>Create or edit projects</p>

// //           <div className='pt-6 sm:pt-8 md:pt-8 lg:pt-8 xl:pt-8 w-full sm:justify-center  flex flex-wrap gap-6 sm:gap-8'>
// //             {projects.map((project) => (
// //               <ProjectCard
// //                 key={project.id}
// //                 img={project.img}
// //                 title={projectsName}
// //                 description={project.description}
// //                 bgColor={project.bgColor}
// //                 textColor={project.textColor}
// //                 link={project.link}
// //               />
// //             ))}
// //           </div>
// //         </div>
// //       </div>

// //       {/* Use Footer */}
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default Projects;
