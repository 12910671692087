import React, { useState, useEffect, useMemo } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import jsPDF from "jspdf";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import JoditEditor from "jodit-react";
import bgimg from "../assets/images/Frame.jpg";
import mike from "../assets/svg/mikeicon.svg";
import playicon from "../assets/svg/playicon.svg";
import pauseicon from "../assets/svg/pauseicon.svg";
import Navbar from "./Layout/Navbar";
import axios from "axios";
import html2canvas from "html2canvas";
import { BASE_URL } from "../config.js";
import SpeechToTextEditor from "./Commonarea";
import Footer from "./Layout/Footer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import save_icon from "../../src/assets/svg/Upload_icon.svg";
import dowload_icon from "../../src/assets/svg/Download_icon.svg";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

const Dashboard2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { qrkey, device } = location.state || {};
  const [isEditing, setIsEditing] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(1000);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [timer, setTimer] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [description, setDescription] = useState("");
  const [recognizedText, setRecognizedText] = useState("");
  const [textContent, setTextContent] = useState("");
  const [isTextEditorEmpty, setIsTextEditorEmpty] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [language, setLanguage] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedFormat, setSelectedFormat] = useState("pdf");
  const [showRecordingControls, setShowRecordingControls] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const transcription = searchParams.get("transcription");
    // alert(transcription);
    if (transcription) {
      setRecognizedText(transcription);
      setTextContent(transcription);
    }
  }, []);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const editorConfig = useMemo(() => {
    return {
      uploader: {
        insertImageAsBase64URI: true,
        url: "/upload_image",
        filesVariableName: function (i) {
          return "images[" + i + "]";
        },
        format: "json",
        method: "POST",
        process: function (resp) {
          return {
            files: resp.files.map((file) => file.url),
          };
        },
        error: function (e) {
          console.error("Error uploading image:", e);
        },
      },
      filebrowser: {
        ajax: {
          url: "/browse_images", // Replace with your image browse URL
        },
        uploader: {
          insertImageAsBase64URI: true,
        },
      },
    };
  }, []); // empty dependency array
  console.log("id", id);

  useEffect(() => {
    let data = JSON.stringify({
      projectId: id,
    });

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        console.log(authToken);

        const response = await axios.post(`${BASE_URL}/api/getDetails`, data, {
          headers: {
            Token: `${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data && response.data.status === "success") {
          const projectData = response.data.data; // Assuming data contains the array of projects
          setProjectName(projectData?.projectName);
          setTextContent(projectData?.description);
          setRecognizedText(projectData?.description);
          console.log(projectData);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    if (id != 123) {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (textContent.trim() !== "") {
      setIsTextEditorEmpty(false);
    } else {
      setIsTextEditorEmpty(true);
    }
  }, [textContent]);
  useEffect(() => {
    setDescription(textContent);
  }, [textContent]);

  // useEffect(() => {
  //   if (recordingStarted && remainingMinutes > 0) {
  //     const timerInterval = setInterval(() => {
  //       setTimer((prev) => prev + 1);
  //       setRemainingMinutes((prev) => prev - 1);
  //     }, 1000);

  //     return () => clearInterval(timerInterval);
  //   }
  // }, [recordingStarted, remainingMinutes]);
  useEffect(() => {
    let timerInterval;

    if (recordingStarted && remainingMinutes > 0) {
      timerInterval = setInterval(() => {
        setTimer((prev) => prev + 1);

        setRemainingMinutes((prev) => {
          const newTime = prev - 1;
          if (newTime <= 0) {
            clearInterval(timerInterval);
          }
          return newTime;
        });
      }, 1000);
    }

    // Clean up interval on component unmount or when dependencies change
    return () => clearInterval(timerInterval);
  }, [recordingStarted, remainingMinutes]);

  // Function to format time in mm:ss format
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (listening) {
      setRecognizedText(transcript);
    }
  }, [transcript, listening]);
  // useEffect(() => {
  //   if (listening && transcript) {
  //     setTextContent(prevContent => {
  //       // Remove any line breaks and concatenate with the new transcript
  //       const newContent = `${prevContent.replace(/\n/g, ' ')} ${transcript}`.trim();
  //       return newContent;
  //     });
  //     setRecognizedText(transcript);
  //     resetTranscript(); // Clear the transcript to avoid duplication
  //   }
  // }, [transcript, listening]);
  // useEffect(() => {
  //   if (listening && transcript) {
  //     setTextContent((prevContent) => {
  //       // Append new transcript without line breaks
  //       const newContent = `${prevContent} ${transcript}`.replace(/\n/g, ' ').trim();
  //       return newContent;
  //     });
  //     setRecognizedText(transcript);
  //     // resetTranscript(); // Clear the transcript to avoid duplication
  //   }
  // }, [transcript, listening]);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Uploaded files:", files);
  };

  const handleConvert = () => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `recorded-speech-${timer}secs.mp3`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleStartRecording = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
    setRecordingStarted(true);
    setIsPlaying(true);
    setShowInstruction(false); // Hide instruction text
    setTimer(0);
    setShowRecordingControls(true); // Show recording controls

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        setStream(stream);
        const mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);

        mediaRecorder.start();
        mediaRecorder.ondataavailable = (event) => {
          if (event.data && event.data.size > 0) {
            const blob = new Blob([event.data], { type: "audio/webm" });
            setAudioBlob(blob);
          }
        };
      })
      .catch((error) => console.error("Error accessing media devices:", error));
  };

  const handlePauseRecording = () => {
    SpeechRecognition.stopListening();
    setRecordingStarted(false);
    setIsPlaying(false);
    if (mediaRecorder) {
      mediaRecorder.pause();
    }
  };

  const handleResumeRecording = () => {
    SpeechRecognition.startListening({ continuous: true });
    setRecordingStarted(true);
    setIsPlaying(true);
    if (mediaRecorder) {
      mediaRecorder.resume();
    }
  };

  const handlePlayClick = () => {
    if (recordingStarted) {
      handlePauseRecording();
    } else {
      handleResumeRecording();
    }
  };

  const handleReset = () => {
    setRecordingStarted(false);
    setShowInstruction(true); // Show instruction text
    setTimer(0);
    // setAudioBlob(null);
    // setRecognizedText('');
    // resetTranscript();
    setShowRecordingControls(false); // Hide recording controls

    if (mediaRecorder) {
      mediaRecorder.stop();
    }

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);

    const translatedText = await translateText(textContent, newLanguage);
    setTextContent(translatedText);
  };

  const translateText = async (text, language) => {
    try {
      const response = await axios.post("/api/translate", {
        text,
        target_lang: language,
      });

      return response.data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      return text;
    }
  };
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleDownload = () => {
    const plainTextContent = stripHtmlTags(textContent);
    const htmlContent = document.querySelector(".jodit-wysiwyg");

    if (selectedFormat === "pdf") {
      html2canvas(htmlContent).then((canvas) => {
        const doc = new jsPDF();
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position -= pageHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save("document.pdf");
      });
    } else if (selectedFormat === "docx") {
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [new TextRun(plainTextContent)],
              }),
            ],
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "document.docx");
      });
    }
  };

  const dashboard2Links = [
    { name: "FAQ", path: "#" },
    { name: "Next", path: "/dashboar3" },
    { name: "Support", path: "#" },
  ];

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition</span>;
  }
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false); // Save and exit edit mode when input loses focus
  };
  const handleCreateProject = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("projectName", projectName);
      formData.append("description", recognizedText);
      formData.append("language", language);

      console.log(formData);
      const authToken = localStorage.getItem("authToken");
      const response = await axios.post(`${BASE_URL}/api/projects`, formData, {
        headers: {
          Token: `${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "success") {
        toast.success("Project created successfully!");
        setModalOpen(false);
        setTimeout(() => {
          navigate("/template");
        }, 3000);
      } else {
        toast.error("Failed to create project.");
      }
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("An error occurred while creating the project.");
    }
  };
  return (
    <>
      <div
        className="min-h-screen flex flex-col"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
        }}
      >
        <ToastContainer />
        <div>
          <Navbar links={dashboard2Links} />
          <div className="w-full lg:mt-8 xl:mt-20 mt-8 sm:mt-12 flex-grow">
            <div className="mx-4 sm:mx-2 lg:mx-16 px-4   xl:mx-20 shadow-md  md:mx-12 p-4  sm:p-8 lg:p-10 lg:pt-6 pb-md-10 pb-4 py-4 rounded-xl pt-md-10 pt-4 bg-white">
              {/* {qrkey && <p>QR Key: {qrkey}</p>} */}
              <div className="">
                <div className="flex justify-end mb-6">
                  {/* {id == "123" ? ( */}
                  <>
                    <button
                      onClick={() => setModalOpen(true)}
                      className="bg-[#008CD2] xs:hidden block text-white sm:px-4 sm:text-[12px] font-semibold text-[15px] inter_ff py-2 px-8 rounded-full"
                    >
                      Save as Template
                    </button>
                    <div className="text-center xs:block hidden">
                      <img
                        className="mx-auto"
                        onClick={() => setModalOpen(true)}
                        width={70}
                        src={save_icon}
                        alt="save_icon"
                      />
                      <p className="text-black text-[13px] font-bold">
                        Save as Template
                      </p>
                    </div>
                  </>
                  {/* ) : null} */}
                </div>
              </div>
              <div className="mt-2 text-center" id="content">
                <JoditEditor
                  config={editorConfig}
                  value={recognizedText}
                  onChange={(recognizedText) =>
                    setRecognizedText(recognizedText)
                  }
                />
              </div>
            </div>
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded-lg w-full max-w-md">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Save Template</h2>
                    <button
                      onClick={() => setModalOpen(false)}
                      className="text-xl"
                    >
                      &times;
                    </button>
                  </div>
                  <form>
                    <Modal.Body>
                      <form>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="name"
                          >
                            Project name
                          </label>
                          <input
                            className="border rounded-lg w-full py-2 px-3 text-gray-700"
                            id="name"
                            type="text"
                            placeholder="Your Name"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                          />
                        </div>
                        {/* <div className="form-group mt-3">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="name"
                          >
                            Description
                          </label>
                          <textarea
                            className="border rounded-lg w-full py-2 px-3 text-gray-700"
                            id="name"
                            type="text"
                            placeholder="Description"
                            disabled
                            value={recognizedText}
                          />
                        </div> */}
                        {/* <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="name"
                          >
                            Language
                          </label>
                          <input
                            className="border rounded-lg w-full py-2 px-3 text-gray-700"
                            id="name"
                            type="text"
                            placeholder="English"
                            value={"English"}
                            disabled
                          />
                        </div> */}
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="flex items-center justify-between">
                        <button
                          className="bg-[#008CD2] text-white font-bold py-2 px-4 rounded-3xl w-full"
                          type="submit"
                          onClick={handleCreateProject}
                        >
                          Save
                        </button>
                      </div>
                    </Modal.Footer>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard2;
