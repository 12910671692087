import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white flex justify-center items-center h-20 xs:h-8 xs:mt-12  sm:h-24 ">
      <p className="text-xs sm:text-sm">
        Copyright © 2024 <span className="text-[#FF9A26]">Mallany</span>. All
        rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
